.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9000;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    transition: 1s;
}

.preloader-mobileTable {
    position: absolute !important;
    /* top: 0; */
    display: flex;
    /* position: relative; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9000;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    transition: 1s;
}

#a {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-weight: 600;
}

.lds-ring {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    margin: 8px;
    border: 15px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(192, 30, 30) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}