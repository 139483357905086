#customer {
    // .ant-col {
    //     width: 25%;
    // }

    .order-link {
        cursor: pointer;
        color: #a90000;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: large;
    }

    #customer-order-detail-table {
        width: 100%;

        th,
        td {
            padding: 0 10px;
            text-align: left;
        }
    }
}

.customer-order-detail-card {
    background-color: #ffffff;
    border-radius: 1em;
    border: solid rgba(128, 128, 128, 0.272) 1px;
    // box-shadow: 2px 2px 4.6px 1.4px #0000003b;
    overflow: hidden;
    transition: box-shadow 500ms;

    .ant-card-head {
        background-color: #d3182b;
        color: #f0f2f5;
    }

    .ant-card-head-title {
        padding: 10px 3em !important;
        font-size: larger;
    }

    .ant-card-body {
        padding: 1em !important;
    }

    &:hover {
        cursor: pointer;
        // background: #f0f2f5;
        // box-shadow: inset 5px 5px 10px #e6e8eb,
        //     inset -5px -5px 10px #fafcff;

    }
}

.ant-page-header-heading-left {
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
}