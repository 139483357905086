.admin-stats {
    text-align: center;
}

.ant-table {
    border-radius: 1em;
    overflow: hidden;
    border: solid rgba(128, 128, 128, 0.272) 1px;
}

.admin-media {
    width: 75vw;
    margin: auto;
    text-align: left;
    .select-item {
        text-align: left;
        width: 60vw !important;
        margin: auto;
    }
}

.steps-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vh;
    min-height: 200px;
    margin-top: 16px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}

.admin-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    gap: 20px;
    @media (max-width: 500px) {
        flex-direction: column;
        padding: 0.25em 0;
    }
}

.fixed-button {
    background-color: rgb(255, 255, 255);
    border: solid #e71d31 1px;
    font-weight: 500;
    // box-shadow: 4px 2px 6px #d8dadd,
    //     -4px -2px 6px #f0eeee;
    margin-right: 10px;
    &:hover {
        // background: #f5f8fc;
        color: #e71d31;
        // border: solid #f1eeee 2px;
        // box-shadow: 2px 1px 4px #d8dadd,
        //     -2px -1px 4px #f0eeee;
    }
}

.info {
    background-color: #17a2b8;
    color: white;
}

#admin,
#user {
    .ant-table-thead>tr>th {
        color: #ffffff;
        background-color: #d3182b;
    }
    .anticon {
        font-size: large !important;
    }
}

#item {
    &>.nav-bar {
        display: flex;
        justify-content: space-between;
        &>div {
            &>button:nth-child(1) {
                font-size: 2rem;
            }
            &>button:nth-child(2) {
                font-size: 2rem;
            }
        }
    }
    .grid {
        row-gap: 1vh !important;
    }
}

.folder {
    margin: 0 10px;
    &-item {
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1em;
        overflow: hidden;
    }
    &>div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &>span {
            font-size: 3rem;
        }
    }
    &>div:nth-child(2) {
        display: flex;
        justify-content: center;
        opacity: 0;
        transition: 1s;
        button {
            margin: 0 10px;
            padding: 0;
        }
    }
    &>p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    &:hover {
        // background: #f0f2f5;
        // box-shadow: inset 5px 5px 10px #e6e8eb,
        //     inset -5px -5px 10px #fafcff;
        cursor: pointer;
        &>div:nth-child(2) {
            opacity: 1;
        }
    }
}

.mobile-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
    &-item {
        background: #ffffff;
        margin-top: 1em;
        border-radius: 1em;
        padding-top: 1em;
        overflow: hidden;
        box-shadow: 0 0 6px 2px #dadada;
        &:nth-child(odd) {
            background: #d3182b;
            color: white;
            a {
                color: #edf06a !important;
            }
        }
        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2px 2em 2px 2em;
        }
        &-action {
            background-color: #ffffff;
            justify-content: flex-end;
            border-top: 1px ridge rgb(192, 192, 192);
            p {
                width: 40%;
                margin: 0;
                @media (max-width: 500px) {
                    width: 60%;
                }
            }
        }
    }
}

.item-cell {
    overflow: scroll;
    overflow-y: hidden;
    width: 100%;
    display: flex;
}

.ondrag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.672);
    z-index: 472368;
    display: grid;
    place-content: center;
    div {
        border: 2px dashed gray;
        border-radius: 2rem;
        padding: 10vh 15vw;
        display: grid;
        span {
            color: #fff;
            font-size: 2.1rem;
        }
        span[role='img'] {
            font-size: 5rem;
        }
    }
}