* {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    font-family: 'Montserrat',
        sans-serif;
}

@import 'main';
@import "customer";
@import 'profile';
@import 'admin';
@import 'loader'