.ant-message {
    z-index: 10000;
}

#order>div:nth-child(4)>div.ant-col.ant-form-item-control>div>div>div>div>span.ant-picker-clear>span {
    display: none !important;
}

.ant-form-item-label>label {
    display: flex !important;
}

.ant-layout {
    background-color: #ffffff;
}

.main-content {
    padding: calc(3vh + 1vw);
    overflow: scroll;
    height: 100vh;
}

.ant-modal {
    border-radius: 1em;
    background-color: #d3182b;
}

.ant-modal-content {
    border-radius: 1em 1em 0 0;
    overflow: hidden;
}

.ant-layout-sider-collapsed {
    @media (max-width: 1024px) {
        flex: 0 0 60px !important;
        max-width: 60px !important;
        min-width: 60px !important;
        width: 60px !important;
    }
}

.trigger {
    position: fixed;
    left: 80px;
    top: 0;
    background: none;
    border: none;
    @media (max-width: 1024px) {
        left: 60px;
    }
}

.side-bar {
    background: #ffffff0c !important;
    border-right: solid rgba(128, 128, 128, 0.156) 1px;
    // box-shadow: 1px 0px 4px 2px #6a6a6a71 !important;
    &>div>ul>li {
        &.selected {
            background: rgb(216, 213, 213) !important;
            // box-shadow: 5px 5px 71px #c1c4c6,
            //     -5px -5px 71px #ffffff;
        }
        &:active {
            background: linear-gradient(145deg, #cccfd2, #f3f6f9);
            // box-shadow: 5px 5px 71px #c1c4c6,
            //     -5px -5px 71px #ffffff;
        }
        a {
            color: black !important
        }
    }
    &-item {
        display: flex;
        align-items: center;
        color: black !important;
        height: 8vh !important;
        margin: 0 !important;
    }
}

body>div>div>div>div>div.ant-tooltip-inner>a {
    color: white;
}

.sidebar-logo {
    position: absolute;
    bottom: 10px;
    left: 5px;
    width: 100%;
}

.logout_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    &>button {
        margin: 1vh 0;
        width: 40vw;
        height: 5vh;
    }
}

#mobile {
    z-index: 5000;
    display: flex;
    position: fixed;
    top: 0;
    justify-content: space-between;
    background-color: #fafafa;
    width: 100%;
    .logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 0;
        padding: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            flex-shrink: 0;
            min-width: 80%;
            min-height: 100%;
            width: 100%;
        }
    }
    .dropdown-icon {
        z-index: 1000000;
        color: black;
        font-size: 1.5rem;
    }
}

.ant-dropdown {
    position: relative !important;
    width: 100%;
}

#mobile-menu {
    z-index: 6000;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.738), rgba(255, 255, 255, 0.471));
    background: -webkit-linear-gradient(to right bottom, rgba(255, 255, 255, 0.363), rgba(255, 255, 255, 0.205));
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    &>li {
        width: 100%;
    }
    &>li>span {
        flex: 1;
        font-size: larger !important;
        &:nth-child(1) {
            svg {
                float: right;
                margin-right: 3vw;
            }
        }
    }
    // &>li>span.ant-dropdown-menu-title-content>a {
    //     font-size: larger !important;
    // }
}

.thumbnail-background {
    position: relative;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    border-radius: 1em;
    overflow: hidden;
    &-style {
        background-color: rgba(255, 255, 255, 0.061);
        // box-shadow: 0px 0px 4px 1px #e3e5e7;
        border: solid rgba(128, 128, 128, 0.272) 1px;
    }
    &-tag {
        position: absolute;
        background-color: #d3182b;
        width: 70%;
        border-radius: 0 0 0 1em;
        text-align: center;
        color: whitesmoke;
        top: 0;
        right: 0;
    }
}

.thumbnail {
    width: -webkit-fill-available;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    transition: transform .2s;
    // &:hover {
    //     object-fit: cover;
    // }
}

.thumbnail-placeholder {
    width: -webkit-fill-available;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    transition: all 1s 2s ease-in-out;
}

.login {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    .ant-form-item-control-input-content {
        display: flex !important;
        justify-content: center;
    }
    &-main {
        background-color: #e71d31;
        display: flex;
        flex-direction: column;
        height: 80vh;
        width: 30vw;
        overflow: hidden;
        border-radius: 1em;
        box-shadow: 0px -2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
        @media (max-width: 767px) {
            height: 100vh;
            width: 100vw;
            border-radius: 0;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            height: 80vh;
            width: 60vw;
        }
        @media (min-width: 1024px) and (max-width: 1366px) {
            width: 60vw;
        }
        &-top {
            z-index: 1;
            display: flex;
            flex-direction: column;
            flex: 2;
            padding: 3vh;
            background-color: white;
            border-radius: 0 0 2em 2em;
            box-shadow: 0 4px 4px 3px rgba(29, 1, 1, 0.523);
            .login-logo {
                flex: 2;
                align-items: center;
                img {
                    width: 60%;
                    @media (max-width: 769px) {
                        width: 80%;
                    }
                }
            }
            input {
                // width: 30vw;
                height: 40px;
                @media (max-width: 769px) {
                    width: 80vw;
                }
            }
        }
        &-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 4vh 2vh 2vh 2vh;
            background-color: #e71d31;
            width: 100%;
            div {
                width: 100%;
            }
            button {
                width: 90%;
                height: 50px;
                font-size: medium;
                font-weight: 700;
            }
        }
    }
}